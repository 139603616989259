.footer_wrapper {
    background: #2E2E2E;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: grid;
    // grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: #F5F5F5;
    padding: 0;
    padding-top: 40px;

    .footer_items {
        width: 95%;
        margin: auto;

        grid-area: 1 / 1 / 3 / 6;
        display: flex;
        align-items: flex-start;

        .items_header_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 50px;

            .items_header {
                margin-bottom: 20px;
                font-weight: 700;
                font-size: 22px;
                line-height: 120%;
            }

            .item_cantent {
                margin: 10px;
                margin-left: 0;
                cursor: pointer;
            }
        }
    }

    .input_area {
        grid-area: 1 / 6 / 3 / 8;

        :nth-child(1) {
            margin-bottom: 30px;
        }

        .input_wrapper {
            position: relative;

            .envelope {
                position: absolute;
                top: 15px;
                left: 10px;
            }
        }

        input {
            background: #424242;
            border-radius: 8px;
            border: none;
            width: 334px;
            height: 50px;
            outline: none;
            padding-left: 13%;
        }

        ::placeholder {
            color: #ADADAD;
            padding-left: 40px;
        }

        button {
            padding: 15px 25px;
            margin-left: 8px;
            background: #F58220;
            border-radius: 8px;
            border: none;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            color: #FFFFFF;
        }
    }

    .rights_area {
        grid-area: 3 / 1 / 4 / 8;
        width: 95%;
        margin: auto;

        .rights_area_description {
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;

                img {
                    margin-left: 5px;
                }
            }

            span {
                font-weight: 700;
                font-size: 15px;
                line-height: 120%;
                color: #F5F5F5;
                margin-left: 5px;
            }

            .footer_heart {
                width: 90px;
                height: 18px;
            }

        }
    }

    hr {
        border: 2px solid #424242;
    }

    @media screen and (max-width:900px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding: 10px;

        .footer_items {
            grid-area: 1 / 1 / 4 / 2;
            margin: 0 auto;

            .items_header_wrapper {
                margin-right: 10px;
            }
        }

        .input_area {
            grid-area: 4 / 1 / 6 / 2;
            margin-top: 20px;

            :nth-child(1) {
                text-align: center;
                margin-bottom: 5px;

            }

            .input_wrapper {
                display: flex;

                input {
                    width: 60%;
                }
            }
        }

        .rights_area {
            grid-area: 6 / 1 / 7 / 2;
        }
    }
}