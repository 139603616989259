.gps {
    position: absolute;
    bottom: 135px;
}

.HomeSearchComponent {
    position: relative;
    z-index: 10
}

.info_wrapper {
    font-family: 'Source Sans Pro';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    position: relative;

    div {
        width: 368px;
        height: 209px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 27px 42px;
        box-sizing: border-box;

        h1 {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            text-align: left;
            letter-spacing: -0.01em;
            color: #5D5D5D;
        }

        h2 {
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.01em;
            color: #5D5D5D;
        }
    }

    .for_your_office {
        margin-top: 160px;
    }

    .budget {
        margin-top: 80px;
    }
}

.offer_box_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 377px;

        h1 {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.01em;
            color: #5D5D5D;
        }

        a {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.01em;
            text-decoration-line: underline !important;
            color: #F58220 !important;
        }
    }
}

@media screen and (max-width:1200px) {
    .info_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;

        div {
            margin-top: 20px;
            width: 287px;
            height: auto;
            // height: 136px;
            z-index: 2;
            padding: 12px 10px;

            h1 {
                font-size: 18px;
                line-height: 30px;
            }

            h2 {
                font-size: 18px;
                line-height: 30px;
            }
        }

        img {
            display: none;
        }

        .gps-icon {
            display: block !important;
            position: absolute;

        }

        .big_icon {
            top: 0;
            left: 280px;
            z-index: 1;
            transform: scale(1.3);
        }

        .small_icon {
            bottom: 20px;
            left: 280px;
            transform: scale(0.9);
        }

        .for_your_office {
            margin-top: 20px;
        }

        .calls_meeting {


            margin-left: 60px;
        }

        .budget {
            margin-top: 20px;
        }
    }

    .offer_box_wrapper {
        width: 360px;
        margin: 10px;

        img {
            width: 180px;
            height: 180px;
        }

        div {
            h1 {
                font-size: 18px;
                line-height: 29px;
                letter-spacing: -0.01em;
            }

            a {
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.01em;
                text-decoration-line: underline;
            }
        }
    }
}