.see_details_wrapper {
    margin-top: 90px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;

    .right_section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width:1200px) {
        justify-content: center;
        .wrapper_button{
            margin-top: 10px;
        }
        .right_section {
            flex-direction: column;
            margin-top: 20px;
        }
        .Foto{
            width: 100%;
            height: auto;
        }
        .fruits_photo {
            display: none
        }


    }
    @media screen and (max-width:650px) {
        margin-top: 0;
    }
}