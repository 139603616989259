.download_app_wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background: linear-gradient(180deg, rgba(255, 160, 51, 0.14) 0%, rgba(255, 160, 51, 0) 100%);
    padding: 10px 270px;
    padding-right: 90px;
}

@media only screen and (max-width:1600px) {
    .download_app_wrapper {
        padding: 10px 10px;
    }
}

.mobile_photo {
    grid-area: 1 / 1 / 7 / 4;
}

.description {
    grid-area: 3 / 4 / 4 / 6;
    font-style: normal;

    .header {
        font-family: 'Source Sans Pro';
        font-weight: 700;
        font-size: 68px;
        line-height: 106%;
        color: #ED3237;
 
    }

    .descrition {
        font-family: 'Open Sans';
        width: 429px;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #000000;
    }
}

.download_store {
    display: flex;
    justify-content: space-between;
    grid-area: 4 / 4 / 5 / 6;
    // margin: 0 auto;
    margin-top: 44px;
    img {
        cursor: pointer;
    }


}

@media screen and (max-width:1350px) {
    .download_app_wrapper {
        width:100%;
        background: white;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: 0 auto;

        .mobile_photo {
            grid-area: 1 / 1 / 3 / 2;
            margin: auto;

            img {

                width: 191px !important;
                height: auto;
            }
        }

        .description {
            grid-area: 1 / 2 / 3 / 3;
            font-weight: 400;

            .descrition_item {
                width: 137px;
                font-size: 16px;
                line-height: 140%;
            }

            .header {
                width: 130px;
                font-size: 20px;
                line-height: 106%;
            }
        }

        .download_store {
            margin: auto;
            margin-top: 10px;
            grid-area: 3 / 1 / 4 / 3;

            img{
                width: 146px;
                height: fit-content;
            }
        }
    }
}