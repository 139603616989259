.menuPage {
  margin: auto;
  margin-bottom: 20px;
  width: 98%;

  .regular_header {
    width: 100%;
    height: 93px;
    background: #F9F9F9;
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 34px;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0.005em;

    span {
      max-width: 1350px;
      margin: 27px auto;
      text-align: left;
      width: 100%;
    }
  }


  .filter_for_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 129.5%;
      color: #5D5D5D;

      img {
        width: 16px;
        height: 12px;
      }
    }
  }

  .regular_menu_body_wrapper {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;

    .regular_menu_body {
      margin-left: 50px;
      width: 100%;

      .restaurant_list_wrapper {
        .restaurant_list_header {
          max-width: 850px;

          .filter_list_wrapper {
            position: relative;
            cursor: pointer;
            width: 130px;

            span {
              width: 130px;
              height: 49px;
              border: 1px solid #000000;
              border-radius: 8px;
              margin: 0 auto;
              font-weight: 400;
              font-size: 18px;
              line-height: 23px;
              padding: 10px 25px;

              img {
                width: 11px;
                height: 5px;
                margin-left: 5px;
                transform: rotate(180deg);
              }

              .active {
                transform: rotate(0);

              }
            }

            .filter_list {
              z-index: 10;
              position: absolute;
              width: 193px;
              height: 247px;
              background: #FFFFFF;
              border-radius: 8px;
              top: 37px;
              box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);

              .filter_list_item {
                cursor: pointer;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.005em;
                padding: 10px;
                color: #2E2E2E;
                display: flex;
                justify-content: space-between;

                &:hover {
                  background: #F0F0F0;
                }
              }
            }


          }
        }
      }
    }

    .product_list_wrapper {

      .product_list_header {
        font-weight: 600;
        font-size: 22px;
        line-height: 129.5%;
        color: #212121;
        margin: 20px 0;
      }

      .show_more_button {
        width: 100%;
        margin: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          border: 1px solid #ED1C24;
          border-radius: 8px;
          font-weight: 400;
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          color: #ED3237;
          background: transparent;
          padding: 10px 40px;
          margin: 0 auto;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .filter_list_wrapper {
      max-width: 120px;

      span {
        max-width: max-content !important;
        padding: 5px 10px !important;
      }
    }
  }
}

.filter_wrapper {
  flex-direction: column;
  width: 260px;

  .close_menu {
    cursor: pointer;

    @media (min-width: 635px) {
      display: none;
    }
  }

  @media (max-width: 635px) {
    position: absolute;
    top: -100px;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #292E34;
    z-index: 999;
    color: #FFF !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll !important;
    padding: 0;


    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    div {
      color: #fff !important;
    }

    input {
      color: #000 !important;
    }

    .filter_menu {
      display: none;
    }

    .LandingLeftMenu {
      border: none;
      top: 0;
      padding: 0;


      .Price {
        border-top: 1px solid #fff;
      }

      .filter_item {
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.regular_menu_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  .active_type {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      .active_type {
        justify-content: flex-start;
      }

    }

    button {
      color: #fff;
      border-radius: 8px;
      background: rgba(32, 32, 32, 0.27);
    }

    .active {
      color: #fff;
      background: #E7792B;
    }
  }

  .HomeSearchComponent {
    margin: 0 auto !important;
    width: 100%;

    .getFoodType {
      width: 100%;
      max-width: 1333px !important;
      margin-top: 0 !important;
      margin-bottom: 40px !important;

      .vehicleInformation {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }

  @media (max-width: 1333px) {
    .HomeSearchComponent {

      .regular_getFoodType {
        width: 95%;

        .getFoodButton {
          width: 100%;
        }

        @media (max-width: 650px) {
          .getFoodType {
            box-shadow: none;
          }

          input {
            width: 100%;
          }
        }
      }
    }
  }
}

.nothing_found {
  position: absolute;
  left: 50%;
  top: 7%;
  text-align: center;
  width: 100px;
  transform: translate(-50%, -50%);

  div:nth-child(1) {
    font-weight: 700;
    font-size: 32px;
    line-height: 160%;
  }
}

.itemsWrapper {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: flex-start;
  column-gap: 30px;

  @media (max-width: 650px) {
    column-gap: 30px;
    justify-content: space-around;
  }

  .adItem {
    width: 240px;

    img {
      position: absolute;
    }
  }

  .item {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 240px;
    height: 320px;
    overflow: hidden;
    position: relative;

    .item_desc {
      height: 50%;

      .item_category {
        height: 15px;
        margin: 8px 0;
      }

      .stars {
        width: 60px;
      }

      .item_restaurant_name {
        margin-top: 5px;

        span {
          color: #000;
        }
      }

      .item_footer {
        margin-top: 10px;
      }
    }

    .heart {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 10px;
    }

    >.itemImage {
      height: 45%;
      width: 240px;
      object-fit: cover;
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    >div {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin: 0 !important;
        height: 46px;
      }

      >div:not(.stars) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: #ED1C24;
          display: flex;
          align-items: center;
        }

        .sale {
          line-height: 18px;
          font-weight: 400;
          text-decoration-line: line-through;
          color: #ADADAD;
          margin-left: 10px;
        }

        button {
          padding: 8px 25px;
          background: #F58220;
          border-radius: 2px;
          border: none;
          color: #FFFFFF;
          align-items: center;
          display: flex;

          img {
            margin-right: 5px;
          }
        }
      }

      span {
        color: #ADADAD;
        font-size: 12px;
      }

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #253D4E;
      }
    }
  }
}

.restaurant_items_Wrapper {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  justify-content: flex-start;

  .adItem {
    width: 240px;

    img {
      position: absolute;
    }
  }

  .item {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 240px;
    height: 320px;
    overflow: hidden;
    position: relative;

    .item_content {
      margin-top: 25px;

      .stars {
        width: 60px;
        margin-top: 10px;
      }

      .item_about {
        margin-top: 10px;

        span {
          width: 50%;
        }
      }

      .location {
        span {
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    >.itemImage {
      height: 144px;
      width: 240px;
      object-fit: cover;
      border-radius: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    >div {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin: 0 !important;
        height: 46px;
      }

      >div:not(.stars) {
        display: flex;
        align-items: center;
        justify-content: space-between;


        button {
          padding: 8px 25px;
          background: #F58220;
          border-radius: 2px;
          border: none;
          color: #FFFFFF;
          align-items: center;
          display: flex;

          img {
            margin-right: 5px;
          }
        }
      }

      span {
        color: #ADADAD;
        font-size: 12px;
      }

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #253D4E;
      }
    }
  }
}

@media screen and (max-width: 945px) {
  .regular_menu_body_wrapper {

    .regular_menu_body {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 635px) {
        margin-left: 0 !important
      }

      .restaurant_list_header {
        justify-content: space-between;
        padding-right: 0;
      }

      .restaurant_list_wrapper,
      .product_list_wrapper {
        width: 100%;
        padding: 0 5px;

        .restaurant_list_header {
          width: 100%;
        }


      }

      .product_list_wrapper {
        width: 100%;

        .product_list_header {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .item_wrapper {
    .item {
      width: 159px;
      height: 201px;

      .itemImage {
        width: 100%;
        height: 45%;
      }

      .item_desc {
        height: 52%;

        .item_footer {
          button {
            padding: 4px 10px;
          }
        }

        .item_name {
          width: 140px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;
        }

        .item_category {
          display: none;
        }
      }

      .item_content {
        margin-top: 0;
        height: 52%;

        p {
          font-size: 16px;
        }

        .stars {
          margin-top: 4px;
        }

        .item_about {
          margin-top: 4px;

          span {
            font-size: 10px;
            width: 100%;
          }

          button {
            padding: 4px 8px;
          }
        }
      }
    }
  }
}