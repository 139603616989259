$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;



.Account {
  display: flex;
  width: 100%;
  padding-bottom: 160px;

  .LandingPanel {
    //padding-left: 40px;
    padding-right: 0;
    width: calc(100% - 250px);

    >div:not(.AccountTabs) {
      padding-left: 50px;
      //border-bottom: 1px solid #DDDDDD;
    }

    h3 {
      font-size: 30px;
      color: #1F1F1F;
      font-weight: 700;
      line-height: 38px;
    }

    .OrderReviews {
      display: flex;
      margin-top: 19px;

      p {
        font-size: 16px;
        color: #1F1F1F;
        line-height: 20px;
        font-weight: 700;

        span {
          font-size: 12px;
          color: #7C7C7C;
          font-weight: 600;
        }

        &:first-of-type {
          margin-right: 35px;
        }
      }
    }

    .AccountTabs {
      margin-top: 20px;

      h5 {
        font-weight: 700;
        font-size: 16px;
        color: #2E2E2E;
        line-height: 20px;
        margin-bottom: 26px;
      }

      .ant-tabs-tabpane {
        padding-left: 50px;
        padding-right: 50px;
      }

      .GuestInfo {
        //padding-right: 50px;
        max-width: 579px;
      }

      .ant-tabs-bar {
        margin-bottom: 24px;
      }

      .ant-tabs-nav-scroll {
        padding-left: 50px;

        .ant-tabs-tab {
          font-size: 16px;
          color: #747474;
          font-weight: 700;

          &.ant-tabs-tab-active {
            color: $red;
          }
        }

        .ant-tabs-ink-bar {
          background-color: $red;
          height: 3px;
        }

        .ant-tabs-nav .ant-tabs-tab:hover {
          color: $red;
        }
      }

      .GuestInputs,
      .CardDetails {
        >div {
          display: flex;
        }

        .SettingsUpdateButton {
          display: flex;
          margin-top: 20px;
          //padding-right: 52px;
          justify-content: flex-end;

          button {
            max-width: 158px;
            width: 100%;
            background: #ED3237;
            border: 1px solid #ED3237;
            border-radius: 8px;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            font-weight: 600;
            display: block;
            //margin: 0 auto;
            padding-top: 7px;
            padding-bottom: 7px;
            height: auto;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            transition: 0.3s all;

            &:hover {
              background: #fff;
              color: #ED3237;
            }
          }
        }

        .GlobalInput {
          max-width: 282px;
          width: 100%;

          &:nth-of-type(odd) {
            margin-right: 15px;
          }
        }

        &.after {
          position: relative;
          padding-bottom: 24px;

          &::after {
            content: '';
            position: absolute;
            width: calc(100% + 52px);
            max-width: 632px;
            height: 1px;
            background-color: #DDDDDD;
            left: 0;
            bottom: 0;
          }
        }
      }

      .CardH {
        margin-top: 32px;
      }

      .PasswordH {
        margin-top: 32px;
        position: relative;
        padding-bottom: 32px;

        span {
          cursor: pointer;
          margin-left: 16px;
          color: $red;
          font-size: 16px;
          font-weight: 700;
        }

        &::after {
          content: '';
          position: absolute;
          width: calc(100% + 52px);
          max-width: 632px;
          height: 1px;
          background-color: #DDDDDD;
          left: 0;
          bottom: 0;
        }

        @media screen and (max-width: 767px) {
          &::after {
            width: 100%;
          }
        }
      }

      .LogOut {
        font-size: 16px;
        color: $red;
        line-height: 20px;
        font-weight: 700;
        cursor: pointer;
      }

      .PopularPanel {
        padding-bottom: 30px;
        padding-right: 90px;
        position: relative;

        &:not(:last-of-type)::after {
          content: '';
          position: absolute;
          width: calc(100% - 90px);
          left: 0;
          bottom: 0;
          height: 1px;
          background-color: #DDDDDD;
        }
      }

      .AllowRate {
        .ant-rate-star {
          cursor: pointer;
          margin-bottom: 2px;
        }
      }

      .PanelTitle {
        position: relative;
        margin-top: 30px;
        margin-bottom: 52px;

        h5 {
          font-size: 24px;
          color: #1B1B1B;
          line-height: 31px;
          font-weight: 700;
          margin-bottom: 0;
        }

        &::after {
          content: '';
          position: absolute;
          width: 94px;
          height: 4px;
          background: #E85D42;
          left: 0;
          bottom: -20px;
        }
      }

      .MyFavorite {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .RectangleItem {
        cursor: pointer;
        margin-bottom: 30px;
        min-width: 250px;

        img {
          display: block;
          width: 240px;
          border-radius: 12px;
          height: 150px;
          object-fit: cover;
        }

        p {
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 16px;
          color: #1F1F1F;
          line-height: 20px;
          font-weight: 600;
        }

        span {
          display: block;
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;

          &.PanelMin {
            margin-top: 4px;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 18px;
            font-weight: 600;

            span {
              display: inline;
              font-weight: 400;
              color: #7C7C7C;
            }
          }

          &.PanelDelivery {
            margin-top: 4px;
          }

          &.PanelFreeDelivery {
            margin-top: 8px;
            display: inline-block;
            background: #F58634;
            border-radius: 6px;
            padding: 4px 6px;
            font-size: 12px;
            color: #FFFFFF;
            font-weight: 700;
            text-transform: uppercase;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .payment_card {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    left: 0;
    -webkit-transition: 0.5s all cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: 0.5s all cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: 0.5s all cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: 0.5s all cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: 0.5s all cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &:nth-of-type(-n+1) {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 0;
    }

    .card_left {
      display: flex;
      align-items: center;

      img {
        height: 30px;
      }
    }

    .card_name {
      padding-left: 15px;
      font-size: 12px;
      color: #2E2E2E;
      font-weight: 700;
      line-height: 15px;
      margin-bottom: 6px;
    }

    .card_right {
      display: flex;
      align-items: center;

      p,
      .make-default {
        padding-right: 10px;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: 700;
        line-height: 15px;
        margin-bottom: 0px;
      }

      .make_default {
        color: $orange;
        cursor: pointer;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;

        &:hover {
          color: $red;
        }
      }

      button {
        font-size: 14px;
        padding-left: 10px;
        color: #888888;
        background-color: transparent;
        border: none;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;

        &:hover {
          color: #2E2E2E;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .card_right {
        display: block;
        text-align: right;

        span {
          display: none;
        }

        p {
          padding-right: 0;
          margin-bottom: 5px;
        }

        button {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding-bottom: 32px;

    .after {
      &::after {
        width: 100% !important;

      }
    }

    .LandingPanel>div:not(.AccountTabs) {
      padding-left: 30px;
      padding-right: 30px;
    }

    .LandingPanel .AccountTabs {
      .GuestInfo {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .AccountTabs {
      .ant-tabs-tabpane {
        padding-left: 15px;
        padding-right: 15px;

        .PopularPanel {
          padding-right: 0;

          &::after {
            width: calc(100% - 0px) !important;
            left: 0 !important;
          }

          .PanelTitle {

            //text-align: center;
            &::after {
              left: 0;
            }
          }

          .MyFavorite {
            justify-content: center;

            .RectangleItem {
              cursor: pointer;
              text-align: center;
              width: 100%;
              flex-grow: 0;
              flex-shrink: 0;

              img {
                width: calc(100% - 15px);
                height: 200px;
                max-height: none;
              }
            }
          }
        }
      }

      .AllReviews {
        ul {
          &::after {
            width: 100% !important;
          }
        }
      }
    }

    .ant-tabs-nav-scroll {
      text-align: center;
      padding-left: 0 !important;
    }
  }


  @media screen and (max-width: 575px) {
    .LandingPanel .AccountTabs {

      .GuestInputs,
      .CardDetails {
        >div {
          flex-wrap: wrap;
        }

        .GlobalInput {
          max-width: none;
          width: 100%;
          margin-right: 0 !important;
        }
      }

      .ant-tabs-tabpane {
        padding-left: 15px;
        padding-right: 15px;
      }

    }
  }
}

.CustomerFeedback {
  .CustomerFeedbackTitle {
    height: 70px;
    width: 100%;
    background: white;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
    -ms-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;

    div {
      width: fit-content;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .ant-modal-close {
    display: none;
  }

  .CustomerFeedbackContent {
    background: white;
    min-height: 400px;
  }

  .CustomerFeedbackFooter:hover {
    cursor: pointer;
    font-size: 21px;
  }

  .CustomerFeedbackFooter {
    height: 70px;
    width: 100%;
    background: white;
    font-size: 20px;
    position: relative;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
    -ms-transition: .3s all;
    -o-transition: .3s all;
    transition: .3s all;

    div {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .CustomerFeedbackForm {
    .center {
      display: flex;
      justify-content: center;
    }

    .feedback-sub-title {
      font-weight: 600;
      font-size: 16px;
      height: 25px;
    }

    .feedback-item {
      margin-bottom: 25px;

      &>.center {
        height: 20px;
      }

      .FeedbackMessageContainer {
        display: flex;
        justify-content: center;
      }
    }

    .FeedbackMessage {
      max-width: 300px;
      border-radius: 5px;
    }

    .disabled {
      opacity: 0.6;
      color: white;
    }

    .customer-feedback-rate {
      background: #ED3237;
      border-radius: 8px;
      max-width: 300px;
      height: 40px;
      width: 100%;
      margin: 20px auto 0;
      display: block;
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 700;
      border: 1px solid $red;
      -webkit-transition: .3s all;
      -moz-transition: .3s all;
      -ms-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }
  }
}

/* todo Custom change password modal */
.CustomChangePassword {
  .ant-modal-body {
    padding: 30px 40px 28px;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-row,
  .ant-form-item {
    width: 100%;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-col,
    .ant-form-item-control {
      width: 100%;
    }

  }

  button {
    margin-top: 25px !important;
  }

  .ChangePasswordTitle {
    font-size: 30px;
    color: #1f1f1f;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 55px;
  }

  .GlobalInput {
    p {
      font-size: 14px;
    }

    input {
      height: 56px;
    }
  }

  form {
    button {
      background: #ED3237;
      border-radius: 8px;
      max-width: 258px;
      width: 100%;
      margin: 20px auto 0;
      display: block;
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 700;
      border: 1px solid $red;
      padding: 14px 0;
      -webkit-transition: .3s all;
      -moz-transition: .3s all;
      -ms-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }
  }

  .ChangePasswordForm {
    label {
      font-size: 14px !important;
      color: #2E2E2E !important;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 13px;

      &::after,
      &::before {
        content: none;
      }
    }

    .ant-form-item-label {
      line-height: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }

    input {
      border: 1px solid #DDDDDD;
      border-radius: 12px;
      /**************/
      height: 56px;
    }

    .LoginForgot {
      text-align: center;

      a {
        margin-bottom: 55px;
        font-size: 14px;
        display: inline-block;
        color: #959595;
        line-height: 18px;
        text-decoration: underline;

        &:hover {
          color: $black;
        }
      }
    }

    .change-password-form-button {
      max-width: 258px;
      width: 100%;
      background: $red;
      border: 1px solid $red;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      font-weight: 600;
      display: block;
      margin: 0 auto;
      padding-top: 14px;
      padding-bottom: 14px;
      height: auto;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &:hover {
        background-color: #fff;
        color: $red;
      }
    }

    .DontHaveAccount {
      font-size: 14px;
      color: #7C7C7C;
      letter-spacing: 0;
      text-align: center;
      line-height: 18px;
      margin-top: 16px;
      margin-bottom: 0;

      a {
        font-weight: 700;
        color: $orange;
      }
    }
  }
}

.singleOrderItemModal {
  .GroupTitle {
    margin-bottom: 13px !important;
  }



  @media screen and (max-width: 767px) {
    .Count {
      position: relative !important;
      display: flex !important;
      justify-content: inherit !important;

      p {
        margin-bottom: 0 !important;
      }
    }

    .Options {
      padding-bottom: 0 !important;
    }
  }
}

.OrderFeedbackRate {
  font-size: 20px;
  padding-left: 10px;
  color: $red;
  background-color: transparent;
  border: none;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;

  &:hover {
    color: #2E2E2E;
  }
}




.accaunt_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 170px 100px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1440px;
  @media screen and (max-width: 800px) {
    justify-content: center;
    padding: 170px 20px;
  }

  @media screen and (max-width: 733px) {
    justify-content: center;
    padding: 170px 10px;
  }
}

.setingsList_wrapper {
  width: calc(100% - 275px);
  box-sizing: border-box;
  min-height: 200px;
}

@media screen and (max-width: 650px) {
  .setingsList_wrapper {
    width: 100%;
    box-sizing: border-box;
  }
}

.mobile_accaunt_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 120px;

  .mobile_settings_button {
    width: 90%;
    margin-bottom: 10px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #292E34;

  }

  .dashboard_wrapper {
    box-shadow: none;

    .dashboard_header {
      display: none;
    }
  }
}