.shipping_payment_wrapper {
    display: flex;
    padding: 100px;
    justify-content: space-between;


    input {
        outline: none;
    }

    button {
        margin-top: 33px;
        background: #F58220;
        width: 280px;
        max-width: 335px;
        height: 44px;
        border: 1px solid #F58220;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        color: #FFFFFF;

    }



    .sp_option_wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        width: 100%;

        .sp_option {
            width: 190px;
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            p {
                font-weight: 700;
                font-size: 26px;
                line-height: 100%;
                color: #212121;
                margin-bottom: 20px;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                    width: 76px;
                    height: 32px;
                    background: #F1F3F8;
                    border-radius: 16px;
                    font-weight: 400;
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    border: none;
                    outline: none;
                    color: #292E34;
                    margin: 0;
                }

                .active {
                    color: #E85D42;
                }
            }

            .scheduled_section {
                margin-top: 20px;
                width: 300px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }



        .sp_user_info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 13px;

            .sc_user_error_message {
                margin-top: 10px;
                background: #FDF6F5;
                border-radius: 4px;
                padding: 10px 50px 10px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                color: #000000;

                img {
                    margin-right: 10px;
                }

                span {
                    padding: 0;
                }

                .navigate_button {
                    color: #F54748;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            span {
                padding: 16px 20px;
                background: #F9F9F9;
                border-radius: 4px;
                font-weight: 400;
                font-size: 20px;
                line-height: 143%;
                color: #5A5B6A;

                &:nth-of-type(2) {
                    margin-left: 10px;
                }
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;

    }

    .shipping_payment_inputs_area {
        width: 60%;
        max-width: 928px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        .sp_header {
            width: 100%;
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;
            color: #212121;
        }

        .radio_area {
            width: 100%;
            display: flex;
            align-items: center;

            input {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        .add_new_card_item {
            margin-right: 30px;

            p {
                font-weight: 900;
                font-size: 18px;
                line-height: 23px;
                color: #212121;
            }

            input {
                width: 335px;
                height: 44px;
                background: #FFFFFF;
                border: 1px solid #D9DCDF;
                border-radius: 4px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #212121;
                outline: none;
            }
        }

        .tip_wrapper {
            width: 735px;
            min-height: 190px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            p {
                font-size: 26px;
                font-weight: 800;
                width: 100%;
            }

            .tip_with_credit {
                width: 336px;

                .tip_credit_card {
                    cursor: pointer;
                    height: 35px;
                    border: 1px solid #F58220;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;
                    color: #F58220;
                    padding: 8px 60px;
                }

                .tip_credit_card_percent {
                    margin-top: 15px;

                    span {
                        cursor: pointer;
                        padding: 8px 23px;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        border: 1px solid #E8E6E6;
                        border-radius: 4px 0px 0px 4px;
                        color: #000000;
                    }

                    .active {
                        background: #F58220;
                        color: #FFFFFF;
                    }
                }
            }

            .tip_with_cash {
                width: 336px;

                .tip_cash {
                    cursor: pointer;
                    height: 35px;
                    border: 1px solid #F58220;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;
                    color: #F58220;
                    padding: 8px 90px;
                    margin: 15px 0;
                }
            }

            .active {
                background: #F58220;
                color: #FFFFFF !important;
            }

            .donate_area {
                display: flex;
                margin-top: 15px;

                span {
                    cursor: pointer;
                    width: fit-content;
                    padding: 7px 17px;
                    max-height: 35px;
                    background: #D9D9D9;
                    border-radius: 4px 0px 0px 4px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .active {
                    background: #F58220;
                    color: #FFFFFF !important;
                }

                input {

                    width: 336px;
                    height: 35px;
                    border: 1px solid #E8E6E6;
                    border-radius: 4px;
                }

                .donate_cash_input {
                    width: 190px !important;
                }
            }

        }
    }

    .add_new_card_wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
        row-gap: 15px;
        column-gap: 0;
    }

    .cards_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .card_wrapper {
            cursor: pointer;
            margin-bottom: 20px;
            margin-right: 18px;
            display: flex;
            flex-direction: column;
            min-width: 214px;
            height: 123px;
            background: #F6F9FC;
            font-weight: 400;
            font-size: 14px;
            color: #4B566B;
            border-radius: 8px;
            padding: 16px;

            img {
                width: 35px;
                height: auto;
            }

            span {
                margin-top: 10px;
            }
        }

        .add_new {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .default {
            border: 1px solid #D23F57;
        }

    }

    .payment_header {
        width: 100%;
        font-weight: 800;
        font-size: 26px;
        line-height: 150%;
        color: #212121;

        margin-bottom: 10px !important;
    }

    .sp_option_checkbox {
        width: 335px;
        display: flex;
        align-items: center;

        input[type=checkbox] {
            width: 23px;
            height: 23px;
        }

        label {
            margin-left: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #212121;
        }
    }
}

.ordered_page_wrapper {
    width: 100%;
    margin-top: 150px;
    height: calc(100vh - 100px);
    min-height: 500px;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            color: #000000;
            margin-bottom: 20px;
        }

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            color: #212121;
            margin-bottom: 12px;

            span {
                color: #F58220;
            }

        }

        button {
            width: 192px;
            height: 56px;
            border: 1px solid #F58220;
            border-radius: 8px;
            background: #ffffff;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #F58220;
        }
    }
}

.donate_wrapper {
    width: 789px;
    height: 250px;
    background: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    .donate_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        color: #212121;
        border-bottom: 1px solid #E7EAED;
        padding: 22px 0;

        img {
            cursor: pointer;
        }
    }

    .donate_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 33px auto;
        justify-content: center;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #212121;

            span {
                text-decoration: underline;
            }
        }

        div {
            display: flex;
            width: 280px;
            justify-content: space-between;


            button {
                width: 125px;
                height: 48px;
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                text-align: center;
                color: #FFFFFF;
                border-radius: 8px;
                background: #F58220;
                border: none;
            }

            .no {
                background: #C4C4C4;
            }


        }
    }
}

.button_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    button {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .back_button {
        padding-right: 50px;
        background: #A4A4A4;
        width: 152px;
        margin-right: 20px;
        outline: none;
    }

    .next_button {
        padding-left: 80px;
        width: 335px;

    }
}

.phone_input_area {
    position: relative;

    input {
        padding-left: 50px !important;
    }

    .phone_country_code {
        position: absolute;
        top: 59px;
        width: 43px;
        height: 42px;
        border-right: 1px solid #D9DCDF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #212121;
        text-align: center;
        box-sizing: border-box;
    }
}

@media (max-width:1200px) {
    .shipping_payment_wrapper {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 0 5px;

        .shipping_payment_inputs_area {
            max-width: 344px;
            width: 100%;
            justify-content: center;
            align-items: center;

        }

        .button_wrapper {
            width: 300px;

            .next_button {
                padding-left: 10px !important;
            }
        }

        .sp_user_info {
            span {
                margin: 5px 0;
                width: 100%;
                text-align: center;
            }

            :nth-of-type(2) {
                margin-left: unset !important;
            }
        }

        .tip_with_credit {
            margin-bottom: 20px;
        }

        .payment_header {
            width: 100%;
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;
            color: #212121;
        }

        .tip_wrapper {
            p {
                width: 100%;
                font-weight: 700;
                font-size: 26px;
                line-height: 100%;
                color: #212121;
            }
        }

        .add_new {
            background: #F6F9FC;
            border-radius: 8px;
            width: 341px;
            height: 43px;
        }

        .card_wrapper {
            width: 343px;
            height: 48px !important;
            flex-wrap: wrap;
            flex-direction: row !important;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            padding: 0 !important;
            box-shadow: 0px 1px 4px rgba(3, 0, 71, 0.19);
            border-radius: 10px;

            span {
                width: 110px;
                margin-top: 0;
            }
        }

        .default {
            border: 2px solid #F58220;
        }
    }
}

@media (max-width:600px) {
    .card_wrapper {
        margin-right: 0 !important;
    }

    .sp_option {
        width: 100% !important;

        div {
            width: 180px;
            justify-content: space-between !important;
        }
    }


    .sp_option_header,
    .sp_header,
    .payment_header {
        width: 100%;
        background: #F9FAFA;
        padding: 10px;
    }
}

@media (max-width:375px) {
    .payment_wrapper {
        width: 100%;



        .card_wrapper {
            margin-right: 0 !important;
        }
    }

    .tip_wrapper {
        width: 100% !important;
        justify-content: center;

        .tip_with_credit {
            width: 100% !important;
        }
    }

    .add_new_card_wrapper {
        width: 100%;

        .add_new_card_item {
            width: 100%;
            margin-right: 0 !important;

            input {
                width: 100% !important;
            }
        }
    }
}