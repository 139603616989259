.shiping_location_wrapper {
    margin-left: 150px;
    margin-top: 100px;
    margin-bottom: 240px;
    display: flex;

    .shipping_location_header {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        color: #212121;
        width: 100%;

    }



    button {
        white-space: pre-wrap;
        width: 359px;
        height: 44px;
        background: #F58220;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #FFFFFF;
        border: 1px solid #FF5722;
        border-radius: 4px;
    }

    .checkbox_area {
        width: 70%;
        display: flex;
        flex-direction: row !important;
        align-items: center;

        input {
            width: 16px;
            height: 16px;
            background: #212121;
            border-radius: 2px;
            margin-right: 10px;
        }
    }

}


.sc_order_summary {
    // margin-left: 28px;
    width: 415px;
    height: fit-content;
    border: 1px solid #E7EAED;
    border-radius: 4px;
    // padding: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;


    div {
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        background: #F9FAFA;
        color: #212121;
        padding: 22px;
    }

    span {

        padding: 5px 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // :nth-child(4) {
    //     font-weight: 600;
    //     margin-top: 10px;
    // }

    button {
        width: 40%;
        margin: 10px auto;
        background: #ffffff;
        border: 1px solid #F58220;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #F58220;
        padding: 10px 0;
    }
}

.shiping_location_inputs_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;

    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 30px;
        margin-bottom: 24px;

        p {
            font-weight: 900;
            font-size: 18px;
            line-height: 23px;
            color: #212121;
        }

        input {
            width: 335px;
            background: #FFFFFF;
            border: 1px solid #D9DCDF;
            border-radius: 4px;
            outline: none;
            padding: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #212121;

        }

        textarea {
            width: 700px;
            height: 93px;
            background: #FFFFFF;
            border: 1px solid #D9DCDF;
            border-radius: 4px;
            resize: none;
            outline: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #898989;
            padding: 15px 10px;

            @media (max-width:1200px) {
                width: 335px;
            }
        }

        .input_zip {
            width: 213px;
        }

        .getFoodButton {
            position: relative;
            margin: 0 !important;
            max-height: 0 !important;

            span {
                position: absolute;
                top: 3px;
                right: 136px;
                background: #F5F5F5;
            }
        }
    }
}

@media (max-width:375px) {
    .shiping_location_inputs_wrapper {
        div:not(.phone_country_code) {
            width: 100% !important;
            margin-right: 0 !important;

            input,
            textarea {
                width: 100%;
            }
        }
    }
}