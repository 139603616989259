.home_page_menu_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    max-width: 1400px;
    width: 100%;
    box-sizing: border-box;
    margin: auto;

    .regular_menu {
        margin-top: 300px
    }

    .home_page_menu {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 350px;
            height: auto;
        }

        div {
            margin-left: 20px;

            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 43px;
                line-height: 112%;
                text-align: left;
                color: #212121;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                letter-spacing: -0.01em;
                color: #5D5D5D;
            }

            button {
                padding: 21px 48px;
                background: #ED3237;
                border-radius: 8px;
                border: none;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                text-align: center;
                color: #FFFFFF;

                &:hover {
                    transform: scale(1.05);
                    transition-duration: 500ms;
                }
            }
        }
    }

    @media screen and (max-width:1024px) {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
       

        .home_page_menu {
            img {
                width: 180px !important;
                height: auto !important;
            }
            div {
                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                }
               
                button {
                    width: 126px;
                    height: 30px;
                    font-size: 16px;
                    line-height: 100%;
                    padding: 0;
                }
            }
        }

        .regular_menu {
            margin-top: 0;
            flex-direction: row-reverse;
        }
    }
}