.anotherRestaurant {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;

    >p {
        width: 100%;
        color: #000;
        background: #FDDBC8;
        padding: 5px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
    }

    .buttons {
        display: flex;
        gap: 25px;

        button {
            background: #F58220;
            border: 1px solid #FF5722;
            border-radius: 4px;
            outline: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: #fff;
            padding: 3px 15px;
        }
    }
}