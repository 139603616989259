.custumers_wrapper {
    margin-top: 60px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: column;

    .custumers_header {
        font-size: 43px;
        line-height: 112%;
        color: #212121;
    }

    .custumers_card {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }

    .custumers_card_wrapper {
        width: 398px;
        height: 423px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 5px;
        transform: scale(0.8);
        transition-duration: 300ms;


        .custumers_card_info {
            width: 100%;
            padding: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            img {
                width: 72px;
                height: 72px;
                background-size: auto;
                border-radius: 50%;
            }

            .profile_image {
                min-width: 72px;
                min-height: 72px;
                background-size: auto;
                border-radius: 50%;
            }



        }


        .custumer_card_desc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .custumer_card_profession {
            font-weight: 400;
            font-size: 18px;
        }

        .custumer_rating {
            width: 160px;

            img {
                width: 14px !important;
                height: 13px !important;
            }
        }
    }

    .active {
        transform: scale(1) !important;
        transition-duration: 300ms;
    }

    .moreDesc {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        padding: 0 20px;
    }

    .desc {
        padding: 0 20px;

    }

    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            margin: 20px;
        }

        .arrow_right {
            transform: rotate(180deg);
        }

        .pagination_item {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin: 15px;
            cursor: pointer;
            background: #C5C5C5;
        }

        .activCycle {
            background-color: #525252;
        }
    }

    @media screen and (max-width:1200px) {

        .custumers_header {
            font-size: 16px;
        }

        .custumers_card_wrapper {
            width: 324px;

        }
    }
}