.date_picker_custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 230px!important;

    .ant-picker {
        width: 110px!important;
        height: 53px;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        border-color: #fff !important;
        margin-top: 20px;
    }

    .ant-picker-input>input {
        color: rgba(0, 0, 0, 0.9) !important;
        font-weight: 600;
        font-size: 12px;
        &::placeholder {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9) !important;
        }
    }

    .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.9) !important;
    }

    button {
        margin-top: 20px;
        width: 125px !important;
        height: 48px;
        background: rgba(245, 130, 32, 0.5);
        border-radius: 8px;
        font-size: 18px;
        color: #FFFFFF;
        border: none;
        outline: none;
    }
}