.catering_bussiness_wrapper {
    width: 1165px;
    margin-top: 50px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    img {
        grid-area: 1 / 1 / 5 / 2;
    }

    h1 {
        width: 380px;
        margin-top: 150px;
        margin-left: -150px;
        grid-area: 2 / 2 / 3 / 3;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #5D5D5D;
    }

    h2 {
        margin-left: -150px;
        width: 600px;
        grid-area: 3 / 2 / 4 / 3;
        font-weight: 400;
        font-size: 20px;
        line-height: 33px;
        letter-spacing: -0.01em;
        color: #5D5D5D;

        a {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: -0.01em;
            text-decoration-line: underline !important;
            color: #F58220 !important;
        }
    }
}

@media screen and (max-width:1200px) {
    .catering_bussiness_wrapper {
        width: 375px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        img {
            grid-area: 1 / 1 / 4 / 4;
            width: 200px;
        }

        h1 {
            width: 142px;
            grid-area: 1 / 4 / 4 / 6;
            padding-top:50px;
            margin-top: unset;
            margin-left:-20px;
            font-size: 18px;
            line-height: 29px;
        }

        h2 {
            width: 90%;
            font-size: 18px;
            line-height: 33px;
            // margin: 0 30px;
            margin-left: unset;
            margin-top: -30px;
            grid-area: 4 / 1 / 6 / 6;
        }
    }
}