.modal {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-size: clamp(18px, 1.9vw, 24px);
    text-align: center;
  }

  div {
    padding: 20px;
    width: 40%;
    max-width: 600px;
    height: 40%;
    max-height: 380px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0 15px #000;
    border-radius: 16px;

    button {
      color: #fff;
      background: #ED3237;
      text-align: center;
      width: 90%;
      height: 50px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 700;
      font-size: 21px;
      border-radius: 32px;
      margin: 0 auto;
      opacity: .9;
      transition: .1s ease-in-out;
      border: 0;
      outline: 0;
      box-shadow: 0 0 3px #000;

      &:nth-of-type(1) {
        margin-bottom: 20px;
      }

      &:hover {
        opacity: 1;
        transform: scale(1.01);
      }
    }
  }

  @media (max-width: 1023px) {
    h2 {
      font-size: 20px;
    }

    div {
      width: 90%;
      height: 400px;
      padding: 10px;

      button {
        height: 50px;
        font-size: 18px;
      }
    }
  }
}