.item_rating {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
        width: 22px;
        height: 22px;
        margin-right: 6px;
    }
    span{
        display: inline;
        width: 120px;
    }
}