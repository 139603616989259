.our_partners_wrapeer {
    margin-top: 220px;
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .our_partners {
        // width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        img {
            width: 100%;
            height: fill-content;
        }
    }

    @media screen and (max-width:1200px) {
        flex-direction: column;
        margin-top: 20px;

    }
}