$black: #1F1F1F;
$red: #ED3237;
$orange: #F58634;

.PanelRow {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1400px) {
    justify-content: flex-start;
  }
}

img[alt=heart] {
  cursor: pointer;
  transition: .05s ease-in-out;
  filter: drop-shadow(0 0 1px #f7f7f7);

  &:hover {
    transform: scale(1.05);
  }
}

.slick-track {
  margin-left: 0 !important;
}

.vehicleInformation {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 8px;
  transition: all .3s;
}

.errInputBox {
  border: 1px solid #ed3237;
}

.errInputBoxMsg {
  color: #ed3237;
  font-size: 12px;
  width: 100%;
  text-align: left;
  margin-top: 3px;
}

/*========================================
          Landing Panel
========================================*/

.Landing {
  display: flex;
  width: 100%;
  padding-bottom: 160px;

  .LandingPanel {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 250px);

    .PanelTitle {
      position: relative;
      margin-bottom: 52px;

      &::after {
        content: '';
        position: absolute;
        width: 94px;
        height: 4px;
        background: #E85D42;
        left: 0;
        bottom: -20px;
      }
    }

    .FirstPanel {
      margin-top: 50px;

      >div:not(.PanelTitle) {
        position: relative;
      }

      .CircleItem {
        cursor: pointer;
        margin-right: 40px;
        width: 76%;

        &:last-of-type {
          margin-right: 0;
        }

        .circleImg {
          width: 150px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          height: 150px;
          overflow: hidden !important;
          position: relative !important;
          background-color: #7C7C7C
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          object-fit: cover;
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in;
          transition: 0.3s all ease-in;
        }

        span {
          margin-top: 13px;
          font-weight: 600;
          font-size: 20px;
          color: $red;
          text-align: center;
          display: inline-block;
          -ms-transform: translateX(calc(75px - 50%));
          transform: translateX(calc(75px - 50%));
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in;
          transition: 0.3s all ease-in;
        }

        @media screen and (min-width: 767px) {
          &:hover {
            img {
              -ms-transform: scale(1.08);
              transform: scale(1.08);
            }

            span {
              color: $orange;
            }
          }
        }
      }
    }

    .TodayPanel {
      margin-top: 50px;

      h5 {
        font-size: 24px;
        color: #1B1B1B;
        line-height: 31px;
        font-weight: 700;
        margin-bottom: 0;

        &+p {
          font-size: 18px;
          color: #7C7C7C;
          margin-bottom: 0;
          line-height: 23px;
        }
      }

      .SquareItem {
        cursor: pointer;
        margin-right: 40px;
        width: 73%;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          display: block;
          width: 150px;
          border-radius: 12px;
          height: 150px;
          object-fit: cover;
        }

        p {
          max-width: 150px;
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 16px;
          color: #1F1F1F;
          line-height: 20px;
          font-weight: 600;
        }

        span {
          max-width: 150px;
          display: block;
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;

          &.PanelCalories {
            margin-top: 4px;
            font-size: 14px;
            color: #F58634;
            line-height: 18px;
            font-weight: 600;
          }

          &.CategoryName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        @media screen and (max-width: 767px) {
          text-align: left;
          padding-left: 15px;

          img {
            margin-left: 0;
          }
        }
      }

      @media screen and (max-width: 767px) {
        margin-top: 25px;
      }
    }

    .PopularPanel {
      margin-top: 50px;

      h5 {
        font-size: 24px;
        color: #1B1B1B;
        line-height: 31px;
        font-weight: 700;
        margin-bottom: 0;

        &+p {
          font-size: 18px;
          color: #7C7C7C;
          margin-bottom: 0;
          line-height: 23px;
        }
      }

      .RectangleItem {
        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          display: block;
          width: 240px;
          border-radius: 12px;
          height: 150px;
          object-fit: cover;
        }

        p {
          max-width: 240px;
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 16px;
          color: #1F1F1F;
          line-height: 20px;
          font-weight: 600;
        }

        span {
          display: block;
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;

          &.PanelMin {
            margin-top: 4px;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 18px;
            font-weight: 600;

            span {
              display: inline;
              font-weight: 400;
              color: #7C7C7C;
            }
          }

          &.PanelDelivery {
            margin-top: 4px;
          }

          &.PanelFreeDelivery {
            margin-top: 8px;
            display: inline-block;
            background: #F58634;
            border-radius: 6px;
            padding: 4px 6px;
            font-size: 12px;
            color: #FFFFFF;
            font-weight: 700;
            text-transform: uppercase;
          }

          &.CategoryName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        @media screen and (max-width: 767px) {
          text-align: left;
          padding-left: 15px;

          img {
            margin-left: 0;
            width: calc(100% - 15px);
            height: 200px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        margin-top: 25px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      .slick-track {
        margin-left: auto !important;
      }

      .slick-slide {
        >div {
          text-align: center;

          img {
            margin-left: 15px;
          }

          span {
            transform: none !important;
          }
        }
      }

      .FirstPanel {
        .CircleItem {
          .circleImg {
            width: 120px;
            height: 120px;
          }

          img {
            width: 100%;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        }

        @media screen and (max-width: 767px) {
          margin-top: 25px;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding-bottom: 70px;

  }

  @media screen and (max-width: 767px) {
    .LandingPanel {
      .PanelTitle {
        padding-left: 15px;

        &::after {
          left: 15px;
        }
      }
    }

  }
}


.slick-arrow.slick-next:not(.slick-disabled) {
  left: auto;
  right: 25px;
  height: 100%;
  z-index: 3;


  &::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    left: calc(100% + 5px);
    top: 84px;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
    z-index: 3;
    opacity: 1 !important;
    background-image: url('../../img/next.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;

  }

  @media screen and (max-width: 420px) {
    right: -15px !important;
  }
}

.slick-arrow.slick-prev:not(.slick-disabled) {
  z-index: 3;
  left: -25px;
  height: 100%;


  &::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    left: 10px;
    top: 84px;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
    z-index: 3;
    opacity: 1 !important;
    background-image: url('../../img/back.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;

  }

  @media screen and (max-width: 767px) {
    left: 25px !important;
  }

  @media screen and (max-width: 420px) {
    left: -15px !important;
  }
}

.LandingSupport {
  margin-top: 60px;
  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  padding: 40px 15px 50px;
  margin-left: -40px;
  margin-right: -40px;

  >div {
    max-width: 361px;
    width: 100%;
    text-align: center;

    >img {
      display: block;
      margin-bottom: 12px;
      height: 52px;
      margin-left: auto;
      margin-right: auto;
    }

    h5 {
      font-size: 20px;
      color: #1F1F1F;
      font-weight: 600;
      line-height: 25px;
    }

    p {
      font-size: 12px;
      color: #7C7C7C;
      line-height: 15px;
      font-weight: 600;
    }

    &:first-of-type {
      margin-right: 32px;

      a {
        font-size: 14px;
        color: #02C594;
        line-height: 18px;
        font-weight: 600;

        img {
          width: 16px;
          margin-left: 6px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &:last-of-type {
      margin-left: 32px;

      a {
        font-size: 14px;
        color: #E85D42;
        line-height: 18px;
        font-weight: 600;

        img {
          width: 16px;
          margin-left: 6px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;

    >div {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;

      &:first-of-type {
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.homeLandingPanel {
  display: block;
  border-bottom: 1px solid #DDDDDD;
  padding-bottom: 24px;
  padding-top: 35px;

  h4 {
    font-size: 24px;
    color: #1F1F1F;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 0;
  }

  img {
    display: block;
    max-width: 398px;
    width: 100%;
    margin: 40px auto;
  }
}