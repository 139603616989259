.selected_restaurant_wrapper {
    margin: 100px 0;
    min-height: 800px;

    .filter_for_mobile {

        width: 100%;
        padding: 10px;


        span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 25px;
        }

        img {
            margin-right: 5px;
        }
    }


    .HomeSearchComponent {
        margin: 0 auto !important;

        .getFoodType {
            max-width: 1333px !important;
            margin-top: 40px !important;
            margin-bottom: 40px !important;
            width: 100%;

            .vehicleInformation {
                width: 100%;
            }

            input {
                width: 100%;
            }
        }

        @media (max-width:1333px) {
            .regular_getFoodType {
                width: 95% !important;

                @media (max-width:650px) {
                    .getFoodType {
                        box-shadow: none;
                    }

                }
            }
        }
    }




    .selected_restaurant_about {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;

        .selected_restaurant_card {
            display: flex;
            margin-right: 30px;

            .restaurant_is_closed {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.6;
                background: #000;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .selected_restaurant_picture {
                max-width: 560px;

                width: 559px;
                height: 331px;
                position: relative;

                @media (max-width:635px) {
                    width: 100%;
                    height: 120px;

                }

                .food_list_img {
                    background-size: 100% 100%;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                }

            }

            .selected_restaurant_description {
                display: flex;
                // justify-content: space-around;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                flex-wrap: 10px;

                .sr_rateing {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 25px;

                    .item_rating {
                        width: 230px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: min-content;

                    }
                }

                .order_type_wrapper {
                    display: flex;

                    div {
                        margin: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        width: 125px;
                        height: 73px;
                        background: #FFFFFF;
                        border: 2px solid #ffffff;
                        border-radius: 6px;
                        font-weight: 400;
                        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
                        letter-spacing: 0.005em;
                        text-align: center;
                        line-height: 25px;

                        span {
                            color: #ED1C24;
                            font-size: 20px;
                        }

                        h1 {
                            color: #000000;
                            font-size: 20px;
                        }
                    }

                    .active {
                        border: 2px solid #F58220;
                    }
                }

                .address {
                    font-size: 20px;
                }

                .order_price {
                    font-size: 18px;
                    margin-bottom: 12px;
                }
            }
        }

        .selected_restaurant_contact {
            width: 372px;
            height: 331px;
            background: #F9F9F9;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width:1200px) {
                display: none;
            }

            div,
            button,
            h1,
            h2 {
                margin: 10px;
            }

            div {
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.005em;
                color: #2E2E2E;
            }

            button {
                width: 223px;
                height: 52px;
                background: #F58220;
                border: 1px solid #F58220;
                border-radius: 6px;
                color: #FFFFFF;
            }

            h2 {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                text-align: center;
                letter-spacing: 0.005em;
                color: #6D6D6D;
            }

            h1 {
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.005em;
                color: #2E2E2E;
            }
        }
    }

    .selected_restaurant_body_wrapper {
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 40px;

        .selected_restaurant_product_wrapper {
            width: 70%;
        }
    }
}

@media (max-width:1200px) {
    .selected_restaurant_about {
        width: 100%;
        justify-content: center !important;

        .selected_restaurant_card {
            flex-direction: column;
            margin-right: unset !important;
            justify-content: center;
            align-items: center;

            .selected_restaurant_picture {
                width: 100%;
                margin-bottom: 10px;

                .food_list_img {
                    border-radius: 8px;
                }
            }

            .selected_restaurant_description {
                @media (max-width:635px) {
                    .address {
                        font-size: 18px;
                    }
                }

                .order_type_wrapper {
                    display: none !important;
                }
            }
        }
    }

    .selected_restaurant_body_wrapper {
        width: 100% !important;
        margin-top: unset !important;

        .selected_restaurant_product_wrapper {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .products_list_wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 95%;

                .show {
                    margin-left: 0;
                    margin: unset;
                }

                .products_list_item_wrapper {
                    margin-bottom: 0;

                    .products_item {
                        height: 132px;

                        .products_item_description {
                            padding: 5px 5px 10px 5px;

                            h1 {
                                width: 100%;
                                height: 53px;
                                min-height: 63px;
                            }

                            p {
                                margin-bottom: 2px;
                            }
                        }
                    }
                }
            }

            .products_item {
                margin: 0 auto;
                margin-bottom: 10px;
            }

            .title {
                width: 100%;
                padding: 14px;

                h3 {
                    font-size: 18px;
                }
            }
        }
    }
}






.products_list_wrapper {
    width: 100%;
    margin-bottom: 20px;

    .products_list_name {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        letter-spacing: 0.005em;
        color: #2E2E2E;
        background: #F9F9F9;
        padding: 10px 20px;
        width: 70%;
        margin-bottom: 50px;
    }

    .products_list_item_wrapper {
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
        flex-wrap: wrap;

        img {
            // margin-top: -60px;
            width: 372px;
            height: auto;
        }

        .products_list_category {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }

        .products_item {
            margin-bottom: 20px;
            box-sizing: border-box;
            width: 45%;
            max-width: 500px;
            min-width: 300px;
            height: 221px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .product_item_img {
                width: 40%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            .products_item_description {
                width: 60%;
                padding: 24px 5px 20px 5px;

                p {
                    width: 80%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    letter-spacing: 0.005em;
                    color: #ED1C24;

                }

                h1 {
                    width: 80%;
                    min-height: 100px;
                    height: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 30px;
                    margin: 0 !important;
                    letter-spacing: -0.01em;
                    color: #5D5D5D;
                }

                h2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 120%;
                    margin: 0 !important;
                    color: #000000;
                }
            }

            .without_photo {
                width: 100%;
                height: 100%;
            }
        }
    }

    .show {
        width: 223px;
        height: 52px;
        margin: 0 auto;
        border: 1px solid #F58220;
        border-radius: 6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.005em;
        background-color: #fff;
        color: #F58220;
        margin-left: 400px;

    }
}

.restaurant_filter {
    width: 400px;

    .LandingLeftMenu {
        margin-top: 0 !important;
        border: unset;
        border-bottom: 1px solid #e7eaed;

    }

    .Price {
        border-top: unset !important;
    }


}

@media (max-width:635px) {
    .ant-slider-track {
        background-color: #69c0ff !important;
    }
}