.shopping_cart_wrapper {
    margin: 110px 90px;
    min-height: 500px;
    .shopping_cart_name {
        font-weight: 700;
        font-size: 26px;
        color: #212121;
        margin-bottom: 22px;
    }

    .sc_section_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        .right_menu_wrapper {
            margin-left: 50px;
        }

        .sc_table {
            border: 1px solid #E7EAED;
            border-radius: 4px;

            .sc_table_header {
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: #F9FAFA;
                border-radius: 4px 4px 0px 0px;
                padding: 19px 25px 19px 27px;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #898989;

                }

                :nth-child(1) {
                    width: 300px;
                }

                // :nth-child(2){
                //     width: 380px;
                // }
                // :nth-child(3){
                //     width: 380px;
                // }
            }

            .sc_item_wrapper {
                // width: 40%;
                display: flex;
                border-bottom: 1px solid #E7EAED;
                padding: 24px;

                .sc_item_image {

                    width: 151px;
                    height: 140px;
                    border-radius: 10px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .sc_item_info {
                    margin-left: 14px;
                    margin-right: 14px;

                    h1 {
                        width: 250px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #212121;
                    }

                    h2 {
                        width: 247px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #898989;
                    }

                    h3 {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 160%;
                        text-decoration-line: underline;
                        color: #FFA033;

                        textarea {
                            resize: none;
                            height: 30px;
                            width: 200px;
                            outline: none;
                            border: 1px solid #E7EAED;
                            color: black;
                        }
                    }
                }

                .sc_item_count {
                    display: flex;
                    justify-content: space-between;
                    height: 36px;
                    align-items: center;

                    div {
                        width: 10px;
                        font-size: 20px;
                        font-weight: 700;
                        cursor: pointer;
                    }

                    span {
                        width: 36px;
                        height: 36px;
                        border: 1px solid #E7EAED;
                        border-radius: 4px;
                        font-size: 16px;
                        line-height: 36px;
                        text-align: center;
                        margin: 0 10px;
                    }
                }

                .sc_item_price {
                    margin-left: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    span {
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 120%;
                        color: #212121;
                    }

                    button {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                        outline: none;
                        border: none;

                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }


            .sc_table_footer {
                width: 100%;
                text-align: right;
                font-weight: 700;
                font-size: 20px;
                line-height: 120%;
                color: #212121;
                padding: 14px 0;
                padding-right: 70px;
                word-spacing: 60px;
            }

        }

        .sc_order_summary {
            width: 415px;
            height: fit-content;
            border: 1px solid #E7EAED;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;


            div {
                width: 100%;
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                background: #F9FAFA;
                color: #212121;
                padding: 22px;
            }

            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 5px 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }



            button {
                width: 80%;
                margin: 10px auto;
                background: #F58220;
                border: 1px solid #F58220;
                border-radius: 4px;
                font-weight: 600;
                font-size: 16px;
                line-height: 160%;
                color: #FFFFFF;
                padding: 10px 0;
            }
        }
    }

    .sc_more_food {
        // margin: 0 auto;
        margin-top: 30px;
        width: 780px;

        .sc_more_food_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 100%;

            .sc_arrow_wrapper {
                display: flex;
                width: 100px;
                justify-content: space-between;
            }

            .arrow {
                cursor: pointer;
                width: 38px;
                height: 38px;
                border: 2px solid #B5B5B5;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

            }
        }
    }

}

.restaurant_cart_item {
    margin-bottom: 30px;
    width: 415px;
    height: 286px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    .item {
        width: 100%;
        height: 100%;

        .heart {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            transition: .2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }

        .restaurantImage {
            width: 100%;
            height: 144px;
            object-fit: cover;
            border-radius: 10px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .restaurant_content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 140px;

            p {
                margin-top: 5px;
                margin-bottom: 5px;
                height: 24px !important;
            }
        }

        .location {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.005em;
            color: #2E2E2E;
        }

        .stars {
            display: flex;

            .price_avg {
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
                color: #ADADAD;
            }
        }
    }
}

.order_summary_wrapper {
    width: 415px;
    margin-left: 28px;
    margin-top: 20px;

    .login_checkout_faster {
        width: 100%;
        height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #F54748;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.005em;
        color: #F54748;
        margin-bottom: 11px;
        cursor: pointer;

        img {
            margin-right: 5px;
        }
    }
}

@media (max-width:1200px) {
    .order_summary_wrapper {
        width: 310px;
        margin-left: 0;
        margin-top: 100px;

        .sc_order_summary {
            width: 100%;
        }
    }

    .shopping_cart_wrapper {
        margin: 110px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .sc_section_wrapper {
            align-items: center;
            justify-content: center;
        }

        .sc_table {
            margin: 0 auto;
        }

        .right_menu_wrapper {
            max-width: 714px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-left: unset !important;

            .restaurant_items_Wrapper {
                margin: 20px 0;
                width: 350px;
            }

            .sc_order_summary {
                width: 310px;
            }
        }
    }

}

@media (max-width:800px) {
    .shopping_cart_wrapper {
        margin: 110px 10px;


        .sc_table_header {
            display: none !important;
        }

        .sc_more_food {
            width: 360px;
        }

    }

}

@media (max-width:720px) {
    .shopping_cart_wrapper {
        margin: 110px 10px;


        .right_menu_wrapper {
            align-items: center;
            justify-content: center;
        }

        .sc_item_wrapper {

            flex-wrap: wrap;
            justify-content: space-between;



            .sc_item_price {
                flex-direction: row !important;
                align-items: center;
                justify-content: space-between;
                margin-left: unset !important;
            }
        }

    }
}

@media (max-width:520px) {
    .restaurant_items_Wrapper {
        width: 100% !important;
        justify-content: space-around;
    }

    textarea {
        width: 180px;
    }

    .shopping_cart_wrapper {
        margin-top: 110px;

        .sc_more_food {
            width: 100%;
        }

        .sc_item_wrapper {
            width: 310px;
        }

        .sc_item_image {

            width: 81px !important;
            height: 72px !important;
        }

        .sc_item_info {
            margin: 0 5px;
            width: 150px;

            h1 {
                width: 180px !important;
            }

            h2 {
                width: 180px !important;
                height: 43px;
                overflow: hidden;
            }

            h3 {
                width: 100%;

                textarea {
                    width: 100%!important;
                }
            }
        }
    }
}

@media (max-width:375px) {
    .you_may_also_like {
        justify-content: center;
        align-items: center;
    }

    .restaurant_items_Wrapper,
    .itemsWrapper {
        justify-content: center;
    }
}