.guest_wrapper {
    margin: 50px 0;
    margin-top: 100px;
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        color: #313030;

    }

    input {
        width: 405px;
        height: 52px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #E7EAED;
        border-radius: 10px;
        outline: none;
        margin: 20px 0;
        font-size: 20px;
    }

    button {
        width: 216px;
        height: 44px;
        background: #F58220;
        border: 1px solid #F58220;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #FFFFFF;
    }
}